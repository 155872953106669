.custom-background {
  background: url('/background.svg') no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  overflow: hidden;
}

.card {
  background: rgba(29, 29, 29, 0.8);
  border-radius: 40px;
  margin: 0 auto;
}

.form-label {
  font-size: 12px;
  text-align: left;
  display: block;
}

.form-control::placeholder {
  font-size: 12px;
}

/* Overide form transparency for inputs, dropdowns and buttons. */
.form-control,
.input-group .form-control {
  background: #ffffff !important;
  font-size: 12px;
  padding: 0.6rem 2.25rem 0.6rem 0.75rem; /* Increase padding for input field, after reducing font size for placeholder. */
}

.btn-primary {
  background: #1c743a !important;
}

.input-group-append {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 999;
}
